import LoaderSpinner from 'react-loader-spinner';
import './Loader.sass';

interface LoaderProps {}

export default function Loader(props: LoaderProps) {
  return (
    <div className="Loader">
      <LoaderSpinner type="TailSpin" color="#00BFFF" height={60} width={60} />
    </div>
  );
}
