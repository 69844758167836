import { useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import moment from 'moment';
import './Post.sass';

export interface PostProps {
  id: number;
  type: 'post' | 'page';
  status: 'draft' | 'published' | 'trash';
  slug: string;
  title: string;
  content: string;
  created_at: string;
  updated_at: string;
}

interface PostCompProps {
  data: PostProps;
}

export default function Post(props: PostCompProps) {
  const { data } = props;

  const [createdAt] = useState(moment(data.created_at));
  const [updatedAt] = useState(moment(data.updated_at));

  return (
    <article className="Post entry">
      <header className="entry-header">
        <div className="entry-meta">
          <Link to={'/' + data.slug} rel="bookmark">
            <time className="published" dateTime={createdAt.toString()}>
              {createdAt.fromNow().toString()}
            </time>
            <time className="updated" dateTime={updatedAt.toString()}>
              {updatedAt.fromNow().toString()}
            </time>
          </Link>
        </div>
        <h3 className="entry-title">{data.title}</h3>
      </header>
      <section className="entry-content">{parse(data.content)}</section>
    </article>
  );
}
