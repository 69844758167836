import parse from 'html-react-parser';
import './Page.sass';

export interface PageProps {
  id: number;
  status: 'draft' | 'published' | 'trash';
  slug: string;
  title: string;
  content: string;
  created_at: string;
}

interface PageCompProps {
  data: PageProps;
}

export default function Page(props: PageCompProps) {
  const { data } = props;

  return (
    <article className="Page">
      <header className="entry-header">
        <h2 className="entry-title">{data.title}</h2>
      </header>
      <section className="entry-content">{parse(data.content)}</section>
    </article>
  );
}
