import { Button } from '..';
import './SearchBoxWidget.sass';

import { IoSearch } from 'react-icons/io5';

interface SearchBoxWidgetProps {}

export default function SearchBoxWidget(props: SearchBoxWidgetProps) {
  return (
    <section className="sidebar-widget SearchBoxWidget">
      <h2 className="widget-title">Site Araması</h2>
      <div className="widget-content">
        <form role="search" method="get" className="search-form" action="/">
          <label
            className="search-label screen-reader-text"
            aria-label="search-form-label"
          >
            Arama Yap
          </label>
          <input
            type="search"
            id="search-form-input"
            className="search-field"
            aria-labelledby="search-form-label"
            name="search"
          />
          <Button type="submit" className="search-submit">
            <span className="screen-reader-text">Ara</span>
            <IoSearch />
          </Button>
        </form>
      </div>
    </section>
  );
}
