import { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import Button from './Button';
import Paper from './Paper';
import type { RoutingProps } from './Routing';
import './TopBar.sass';

const menuItems = [
  { path: '/', label: 'Ana Sayfa' },
  { path: '/hakkimda', label: 'Hakkımda' },
];

export default function TopBar({ routes }: RoutingProps) {
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();

  const onClick = () => setTimeout(() => setMenuOpen(false), 0);

  return (
    <header className="TopBar">
      <div className="branding">
        <Paper>
          <h1 className="site-title">
            <Link to="/" onClick={onClick}>
              Blog
            </Link>
          </h1>
          <p className="site-description">Yaşanabilir bir dünya, yeniden?</p>
        </Paper>
      </div>
      <div className={'navigation' + (menuOpen ? ' open' : '')}>
        <div className="toggle-menu">
          <Button onClick={() => setMenuOpen(!menuOpen)}>Menü</Button>
        </div>
        <Paper>
          <nav className="menu">
            <ul>
              {menuItems.map((route) => (
                <li
                  className={
                    route.path === location.pathname ? 'active' : undefined
                  }
                  key={route.path}
                >
                  <Link to={route.path} onClick={onClick}>
                    {route.label}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </Paper>
      </div>
    </header>
  );
}
