import Paper from './Paper';
import './BottomBar.sass';

import { IoLogoInstagram } from 'react-icons/io';

export default function TopBar() {
  return (
    <footer className="BottomBar">
      <Paper className="socials">
        <a
          className="social"
          href="https://www.instagram.com/fkakkus/"
          target="_blank"
          rel="noreferrer"
        >
          <IoLogoInstagram />
        </a>
      </Paper>
    </footer>
  );
}
