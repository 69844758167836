import { LinksWidget, SearchBoxWidget } from './SidebarWidgets';
import './Sidebar.sass';

interface SidebarProps {
  children?: React.ReactNode | undefined;
}

export default function Sidebar({ children }: SidebarProps) {
  return (
    <div className="Sidebar">
      <SearchBoxWidget />
      <LinksWidget />
      {children}
    </div>
  );
}
