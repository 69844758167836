import { useState } from 'react';
import { Link } from 'react-router-dom';
import parse from 'html-react-parser';
import moment from 'moment';
import type { PostProps } from './Post';
import './PostPreview.sass';

interface PostPreviewCompProps {
  data: PostProps;
}

export default function PostPreview(props: PostPreviewCompProps) {
  const { data } = props;

  const [createdAt] = useState(moment(data.created_at));
  const [updatedAt] = useState(moment(data.updated_at));

  return (
    <article className="PostPreview entry">
      <header className="entry-header">
        <div className="entry-meta">
          <Link to={'/' + data.slug} rel="bookmark">
            <time className="published" dateTime={createdAt.toString()}>
              {createdAt.fromNow().toString()}
            </time>
            <time className="updated" dateTime={updatedAt.toString()}>
              {updatedAt.fromNow().toString()}
            </time>
          </Link>
        </div>
        <h3 className="entry-title">
          <Link className="post" to={'/' + data.slug} rel="bookmark">
            {data.title}
          </Link>
        </h3>
      </header>
      <section className="entry-content">{parse(data.content)}</section>
    </article>
  );
}
