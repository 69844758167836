import './LinksWidget.sass';

interface LinksWidgetProps {}

export default function LinksWidget(props: LinksWidgetProps) {
  return (
    <section className="sidebar-widget LinksWidget">
      <h2 className="widget-title">Bağlantılar</h2>
      <div className="widget-content">
        <nav className="sidebar-links-container">
          <ul className="sidebar-links">
            <li className="sidebar-link">
              <a rel="noreferrer" target="_blank" href="https://unsplash.com/">
                Unsplash – Beautiful Photo Community
              </a>
            </li>
            <li className="sidebar-link">
              <a rel="noreferrer" target="_blank" href="https://threejs.org/">
                three.js – JavaScript 3D Library
              </a>
            </li>
            <li className="sidebar-link">
              <a rel="noreferrer" target="_blank" href="https://reactjs.org/">
                React – JavaScript UI Library
              </a>
            </li>
            <li className="sidebar-link">
              <a
                rel="noreferrer"
                target="_blank"
                href="https://www.electronjs.org/"
              >
                Electron – JavaScript Desktop Apps
              </a>
            </li>
            <li className="sidebar-link">
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.wolframalpha.com/"
              >
                WolframAlpha – Computational Intelligence
              </a>
            </li>
            <li className="sidebar-link">
              <a
                rel="noreferrer"
                target="_blank"
                href="http://www.yildizrobocon.org/"
              >
                Yıldız Robocon
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </section>
  );
}
