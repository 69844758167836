import './Pagination.sass';

export interface MetaProps {
  current_page: number;
  first_page: number;
  first_page_url: string;
  last_page: number;
  last_page_url: string;
  next_page_url: string | null;
  per_page: number;
  previous_page_url: string | null;
  total: number;
}

interface PaginationCompProps {
  meta: MetaProps;
}

export default function Pagination(props: PaginationCompProps) {
  const { meta } = props;

  return (
    <div className="Pagination">
      {meta.previous_page_url ? (
        <a href={meta.previous_page_url}>Previous</a>
      ) : (
        <span></span>
      )}
      {meta.next_page_url ? (
        <a href={meta.next_page_url}>Next</a>
      ) : (
        <span></span>
      )}
    </div>
  );
}
