import axios from 'axios';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useLocation } from 'react-router-dom';
import {
  Loader,
  Mainbar,
  PageNotFound,
  Pagination,
  Paper,
  Posts,
  Sidebar,
} from 'src/components';
import { MetaProps } from 'src/components/Pagination';
import queryString from 'query-string';
import Config from 'src/config';

export default function AllPosts(props: RouteComponentProps<{}>) {
  const location = useLocation();

  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  // prettier-ignore
  const [meta, setMeta] = useState<MetaProps>({
    current_page: 1,
    first_page: 1,
    first_page_url: '?page=1',
    last_page: 1,
    last_page_url: '?page=1',
    next_page_url: null,
    per_page: 1,
    previous_page_url: null,
    total: 0
  });

  useEffect(() => {
    setLoading(true);

    const { page, search } = queryString.parse(location.search);

    document.title = 'Ana Sayfa';

    axios({
      method: 'get',
      url: `${Config.api.url}/v1/posts`,
      params: { page, search },
    })
      .then(({ data }) => {
        setPosts(data.data);
        setMeta(data.meta);
        setTimeout(() => setLoading(false), 100);
      })
      .catch((reason) => {
        console.warn(reason);
        setTimeout(() => setLoading(false), 100);
      });
  }, [location]);

  return (
    <Paper className="page all-posts with-sidebar">
      <header className="page-header">
        <h2 className="page-title">İletiler</h2>
      </header>
      <Mainbar>
        {loading ? (
          <Loader />
        ) : posts.length <= 0 ? (
          <PageNotFound />
        ) : (
          <Posts list={posts} meta={meta} />
        )}
        <Pagination meta={meta} />
      </Mainbar>
      <Sidebar />
    </Paper>
  );
}
