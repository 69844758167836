import React, { MouseEventHandler } from 'react';
import './Button.sass';

interface ButtonProps
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  children?: React.ReactNode | undefined;
  className?: string | undefined;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  type?: 'button' | 'submit' | 'reset';
}

export default function Button(props: ButtonProps) {
  const { children, className, onClick, type, ...rest } = props;

  return (
    <button
      type={type || 'button'}
      className={'Button' + (className ? ` ${className}` : '')}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
