import PostPreview from './PostPreview';
import type { PostProps } from './Post';
import './Posts.sass';

export interface PostsProps {
  list: PostProps[];
  meta: any;
}

export default function Posts(props: PostsProps) {
  const { list } = props;

  return (
    <div className="Posts">
      {list.map((post: PostProps, key) => (
        <PostPreview data={post} key={key} />
      ))}
    </div>
  );
}
