import { BrowserRouter as Router } from 'react-router-dom';
import './App.sass';

import BottomBar from 'src/components/BottomBar';
import Routing from 'src/components/Routing';
import TopBar from 'src/components/TopBar';
import Home from 'src/pages/Home';
import SinglePost from 'src/pages/SinglePost';
import moment from 'moment';
import 'moment/locale/tr';

document.documentElement.lang = 'tr';
moment.locale('tr');

const routes = [
  {
    label: 'Ana Sayfa',
    path: '/',
    component: Home,
    exact: true,
  },
  {
    label: '',
    path: '/:id',
    component: SinglePost,
    exact: true,
    visible: false,
  },
];

function App() {
  return (
    <Router>
      <TopBar routes={routes} />
      <Routing routes={routes} />
      <BottomBar />
    </Router>
  );
}

export default App;
