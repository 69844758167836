import axios from 'axios';
import { useEffect, useState } from 'react';
import { RouteComponentProps, useParams } from 'react-router-dom';
import {
  Loader,
  Mainbar,
  Page,
  PageNotFound,
  Paper,
  Post,
  Sidebar,
} from 'src/components';
import { PostProps } from 'src/components/Post';
import Config from 'src/config';

export default function SinglePost(props: RouteComponentProps<{}>) {
  // prettier-ignore
  const params: { id: string } = useParams();

  const [loading, setLoading] = useState(true);
  // prettier-ignore
  const [post, setPost] = useState<PostProps>({
    id: 0,
    type: 'post',
    status: 'draft',
    slug: '',
    title: '',
    content: '',
    created_at: '',
    updated_at: '',
  });

  const [invalid, setInvalid] = useState(false);

  useEffect(() => {
    setLoading(true);

    let cancelTokenSrc = axios.CancelToken.source();

    function fetch() {
      axios({
        method: 'get',
        url: `${Config.api.url}/v1/posts/${params.id}`,
        cancelToken: cancelTokenSrc.token,
      })
        .then(({ data }: { data: PostProps }) => {
          setPost(data);
          document.title = data.title;
          setTimeout(() => setLoading(false), 100);
        })
        .catch((reason) => {
          console.warn(reason);
          if (axios.isCancel(reason)) return;

          setInvalid(true);
          setTimeout(() => setLoading(false), 100);
        });
    }

    fetch();

    return () => {
      cancelTokenSrc.cancel('Component unmount');
    };
  }, [params]);

  return (
    <Paper className="page single-post with-sidebar">
      <Mainbar>
        {loading ? (
          <Loader />
        ) : invalid ? (
          <PageNotFound />
        ) : post.type === 'page' ? (
          <Page data={post} />
        ) : (
          <Post data={post} />
        )}
      </Mainbar>
      <Sidebar />
    </Paper>
  );
}
